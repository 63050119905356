import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ORDER_STORAGE_KEY } from '../constants/config';

@Injectable({ providedIn: 'root' })
export class LocalstorageService {
  isBrowser: any;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  setItem(name: string, data: any) {
    if (this.isBrowser) {
      localStorage.setItem(name, data);
    }
  }

  getItem(name: string): any {
    if (this.isBrowser) {
      return localStorage.getItem(name);
    } else {
      return null;
    }
  }

  removeItem(name: string) {
    if (this.isBrowser) {
      return localStorage.removeItem(name);
    }
  }

  getUser() {
    if (this.getItem(ORDER_STORAGE_KEY)) {
      const data = JSON.parse(this.getItem(ORDER_STORAGE_KEY) || '{}');
      if (data?.registration_step) {
        return {
          ...data.registration_step,
          role: data.role,
          order_id: data?.order_id,
          not_filled: data?.not_filled
        };
      }
    }
    return null;
  }

  getProduct() {
    if (this.getItem(ORDER_STORAGE_KEY)) {
      const data = JSON.parse(this.getItem(ORDER_STORAGE_KEY) || '{}');
      if (data?.product) {
        return data?.product;
      }
    }
    return null;
  }
}
