import { Component, OnInit, inject } from '@angular/core';
import { DialogContainerComponent } from '../../../../shared/components/general/dialog-container/dialog-container.component';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { STATE_LIST } from '../../../constants/config';
import { Platform } from '@angular/cdk/platform';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-city-dialog',
  standalone: true,
  imports: [
    DialogContainerComponent,
    CommonModule,
    MatDialogModule,
    KeyValuePipe,
    MatSelectModule,
    MatFormFieldModule
  ],
  templateUrl: './city-dialog.component.html'
})
export class CityDialogComponent implements OnInit {
  public dialogRef = inject(MatDialogRef<CityDialogComponent>);
  private platform = inject(Platform);
  private localStorage = inject(LocalstorageService);
  states = STATE_LIST;
  selectedState!: any;
  isMobilePlatform = false;

  ngOnInit(): void {
    this.isMobilePlatform = this.platform.IOS || this.platform.ANDROID;
    if (this.localStorage.getItem('state')) {
      this.selectedState = JSON.parse(
        this.localStorage.getItem('state') || '{}'
      );
    }
  }

  toggle(item: any) {
    this.selectedState = item;
  }

  change(event: MatSelectChange) {
    this.selectedState = { ...this.states[event.value], id: event.value };
  }

  confirm() {
    this.dialogRef.close(this.selectedState);
  }

  asIsOrder(a: { key: string }, b: { key: string }): number {
    return b.key === '1' ? -1 : 0;
  }
}
